import Prism from 'prismjs';

import "foundation-sites/js/foundation/foundation";
import 'foundation-sites/js/foundation/foundation.topbar';
import 'waypoints/lib/jquery.waypoints.min.js';
import 'waypoints/lib/shortcuts/infinite.min.js';

$(document).ready(function() {

    function checkWidth() {

        var windowsize = $(window).width();
        var $widget = $('.calendly-inline-widget iframe');

        if (windowsize < 1140) {
            $widget.removeAttr('scrolling');
        } else {
            $widget.attr('scrolling', 'no');
        }
    }

    // Execute on load
    checkWidth();
    // Bind event listener
    $(window).resize(checkWidth);
});

$(document).ready(function() {

    $(document).foundation();

    // Toggle Apply Now Link on Jobs page
    $("a.apply").on('click', function(e) {
        e.preventDefault();
        $(".application-options").fadeIn('slow').removeClass('hidden');
    });

    $("#deleteUserPhotoAction").on('click', function() {

        document.getElementById("deleteUserPhotoInput").value = 'swish';

        var photo = $('#profile-photo').attr('data-fallback');
        $('#profile-photo').attr('src', photo);
    });

    // Topic Dropdown Navigation
    $('#nav-dropdown-topics').click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        if ($('#nav-dropdown-topics-flyout').hasClass('hidden')) {
            $('#nav-dropdown-topics-flyout').removeClass('hidden');
        } else {
            $('#nav-dropdown-topics-flyout').addClass('hidden');
        }
    });

    $('#nav-dropdown-topics-flyout').click(function(e) {
        e.stopPropagation();
    });

    $('body').click(function(e) {
        if (!$("#nav-dropdown-topics-flyout").hasClass('hidden')) {
            $('#nav-dropdown-topics-flyout').addClass('hidden');
        }
    });

    new window.Waypoint.Infinite({
        element: $('.infinite-container')[0]
    });

    // Highlight all matching syntax
    Prism.highlightAll();
});

$('.mobile-nav').click(function() {
    if ($('.top-bar').hasClass('expanded')) {
        $('.top-bar').removeClass('expanded');
        $('.top-bar').parent('div').addClass('fixed');
        $('body').addClass('f-topbar-fixed');
    } else {
        $('.top-bar').addClass('expanded');
        $('.top-bar').parent('div').removeClass('fixed');
        $('body').removeClass('f-topbar-fixed');
    }
});

var logoWidth = parseInt($('.brand').outerWidth());
var logoMargin = parseInt($('.brand').css('marginLeft'));
var logo = logoWidth + logoMargin;
var leftNavWidth = parseInt($(".top-bar-section .left").outerWidth());
var leftNavMargin = parseInt($(".top-bar-section .left").css('marginLeft'));
var leftNav = (leftNavWidth + leftNavMargin);
var rightNav = $(".top-bar-section .right").outerWidth();

function navSize() {
    var navigationContainer = $(".top-bar").outerWidth();
    var navigation = (parseInt(logo + leftNav + rightNav));

    if (navigation >= navigationContainer) {
        $('.top-bar').addClass('mobile');
        $('.mobile-nav').addClass('mobile');
        $('.has-dropdown').addClass('mobile');
        $('#nav-dropdown-topics-flyout').addClass('mobile');
        if ($('.has-dropdown').hasClass('not-click')) {
            $('.has-dropdown').removeClass('not-click');
            $('.has-dropdown').addClass('prevent-not-click');
        }
    } else {
        if ($('.top-bar').hasClass('mobile')) {
            $('.top-bar').removeClass('mobile');
            $('.mobile-nav').removeClass('mobile');
            $('.has-dropdown').removeClass('mobile');
            $('#nav-dropdown-topics-flyout').removeClass('mobile');
        }
        if ($('.has-dropdown').hasClass('prevent-not-click')) {
            $('.has-dropdown').removeClass('prevent-not-click');
            $('.has-dropdown').addClass('not-click');
        }
        if ($('.top-bar').parent('div').addClass('fixed') === false) {
            $('.top-bar').parent('div').addClass('fixed');
            $('body').addClass('f-topbar-fixed');
        }
    }
}

$(window).on('load', function() {
    navSize();
});
$(window).resize(function() {
    navSize();
});
$(window).scroll(function() {
    var topicsNavHeight = $('#nav-dropdown-topics-flyout').outerHeight();
    var footerHeight = $('.footer').outerHeight();

    if ($('#nav-dropdown-topics-flyout').hasClass('absolute')) {
        $('#nav-dropdown-topics-flyout').removeClass('absolute');
    }

    if (($('#nav-dropdown-topics-flyout').offset().top + footerHeight) > ($('.footer').offset().top)) {
        $('#nav-dropdown-topics-flyout').addClass('absolute');
    }
});

function toggleSearch(e) {
    e.preventDefault();

    if ($(".search-container").hasClass("expanded")) {
        $(".search-container").removeClass("expanded");
    } else {
        $(".search-container").addClass("expanded");
        $("#primary-search").focus();
    }
}

function checkInputValues() {
    if ($('#email').val() == currentEmail && $('#newPassword').val() === '') {
        $('.update-profile').attr('disabled', 'disabled');
    } else {
        $('.update-profile').removeAttr('disabled');
    }
}

$('.search-site').click(function(e) {
    toggleSearch(e);
});

$('.close-search').click(function(e) {
    toggleSearch(e);
});

$('#nav-dropdown-topics-flyout .close').click(function(e) {
    e.preventDefault();
    $(this).parents('.dropdown').addClass('hidden');
});

$('#email').on('keyup', function() {
    checkInputValues();
});

$('#newPassword').on('keyup', function() {
    checkInputValues();
});

$('.modal-shade').on('click', function() {
    $('.authorize').addClass('hidden');
    $('.modal-shade').hide();
    $('#password').val('');
    $('#email').removeAttr('readonly');
    $('#newPassword').removeAttr('readonly');
    $('#password').removeAttr('readonly');
});

$(window).scroll(function() {
    if (!$('.authorize').hasClass('hidden')) {
        var topMargin = -($(document).scrollTop());
        $('.authorize').css({
            'margin-top': topMargin
        });
    }
});

$(window).scroll(function() {
    var topMargin = -($(document).scrollTop());
    $('.modal').each(function() {
        $('.modal:not(.hidden)').css({
            'margin-top': topMargin
        });
    });
});

//lazy load images
$(function() {
    let $photoElements = $('li.professional .profile-photo');

    if ($photoElements) {
        var totalElements = $photoElements.length,
            batchSize = 20,
            timeoutInterval = 0,
            lazyLoadImages = function($array) {
                setTimeout(function() {
                    $array.each(function() {
                        if ($(this).data('img-src').length > 0) {
                            $(this).attr('src', $(this).data('img-src'));
                        }
                    });
                }, timeoutInterval);
                timeoutInterval += 1000;
            };

        for (let i = 0, j = $photoElements.length; i < j; i += batchSize) {
            $subset = $photoElements.slice(i, i + batchSize);
            lazyLoadImages($subset);
        }
    }
});

// sorting for professional listings
$(document).ready(function() {
    $('.sort-by-menu a').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        if ($(this).hasClass('active')) {
            return;
        }

        var sortBy = $(this).data('sort-by'),
            $professionalsList = $('.professionals-list');

        //adjust sortBy (profileName & craftingSince especial cases)
        if (sortBy !== 'profileName' && sortBy !== 'years') {
            sortBy += 'Count';
        }

        // set active sort by link
        $('.sort-by-menu a').removeClass('active');
        $(this).addClass('active');

        // sort professionals
        $professionalsList.animate({opacity: 0}, 200, function() {
            $(".professional").sort(sort_li).each(function() {
                var count = parseInt($(this).data(sortBy), 10);

                //reset visibility of all elements
                $(this).show();

                if (sortBy !== 'profileName' && sortBy !== 'years') {
                    if (count === 0) {
                        $(this).hide();
                    }
                }

                $(this).appendTo('.professionals-list');
            });

            setTimeout(function() {
                $professionalsList.animate({opacity: 1});
            }, 100);
        });

        function sort_li(a, b) {
            if (sortBy === 'profileName') {
                return ($(a).data(sortBy).toUpperCase().localeCompare($(b).data(sortBy).toUpperCase()));
            } else {
                if (sortBy === 'years') {
                    var years1 = $(a).data(sortBy),
                        years2 = $(b).data(sortBy),
                        plugin1 = $(a).data('pluginCount'),
                        plugin2 = $(b).data('pluginCount'),
                        name1 = $(a).data('profileName'),
                        name2 = $(b).data('profileName');

                    if (years1 < years2) {
                        return -1;
                    }
                    if (years1 > years2) {
                        return 1;
                    }
                    if (plugin1 > plugin2) {
                        return -1;
                    }
                    if (plugin1 < plugin2) {
                        return 1;
                    }

                    //fallback sort by name
                    return (name1.toUpperCase().localeCompare(name2.toUpperCase()));
                } else {
                    return ($(b).data(sortBy)) > ($(a).data(sortBy)) ? 1 : -1;
                }
            }
        }
    });

    // Home Page Topic Toggle
    $(document).ready(function() {
        $(".accordion li.accordion-navigation a").on("click", function(event) {
            event.preventDefault();
            $('#topics-container').slideToggle('fast');
        });
    });
});
